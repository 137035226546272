
import Vue from "vue";
import VueClipboard from 'vue-clipboard2'
import whatsapp from "./icons/whatsapp";
import telegram from "./icons/telegram";
import instagram from "./icons/instagram";
import messenger from "./icons/messenger";

Vue.use(VueClipboard)
export default Vue.extend({
  data() {
    return {
      linkTo: "whatsapp" as string,
      myContact: "",
      vertical: "bottom",
      horizontal: "right",
      distanciaHorizontal: 3,
      distanciaVertical: 3,
      networks: {
        whatsapp,
        messenger,
        telegram,
        instagram
      } as { [k: string]: string }
    };
  },
  methods: {
    getImage(linkTo: string): any {
      return this.networks[linkTo].replace(/\n/g, '');
    },
    notificarCopiado() {
      this.$buefy.notification.open({
          message: 'Copiado!',
          type: 'is-success'
      })
    }
  },
  computed: {
    generatedCode() {
      return `<inject_script type="text/javascript">
          setTimeout((function(window) {
            var css = '${this.generateStyles.replace(/\n/g, '').replace(/\s{2,}/g, '')}',
            head = window.document.head || window.document.getElementsByTagName('head')[0],
            style = window.document.createElement('style');
            head.appendChild(style);
            style.type = 'text/css';
            style.appendChild(window.document.createTextNode(css));
            var a = window.document.createElement('a')
            a.href='${this.contactLink}'
            a.target='_new'
            a.className='${this.linkTo} ${this.vertical} ${this.horizontal}'
            window.document.children[document.children.length - 1].appendChild(a)
            var doc = new DOMParser().parseFromString('${this.getImage(this.linkTo)}','application/xml');
            a.appendChild(a.ownerDocument.importNode(doc.documentElement, true))
          })(window), 2000)
        </inject_script>`
    },
    generatedLinkCode() {
      return `
      <a href="${this.contactLink}" target="_new" class="${this.linkTo} ${this.vertical} ${this.horizontal}">${this.getImage(this.linkTo)}</a>
      `.replace(/\n/g, '')
    },
    generateStyles() {
      return `
          .${this.linkTo} {
            position: absolute;
            width: 4em;
            height: 4em;
            z-index: 100;
          }

          .${this.linkTo} svg {
            width: 100%;
            height: 100%;
          }

          .${this.linkTo}.top {
            top: ${this.distanciaVertical}em;
          }

          .${this.linkTo}.bottom {
            bottom: ${this.distanciaVertical}em;
          }

          .${this.linkTo}.left {
            left: ${this.distanciaHorizontal}em;
          }

          .${this.linkTo}.right {
            right: ${this.distanciaHorizontal}em;
          }
      `
    },
    generatedCodeReplace() {
      return this.generatedCode.replace(/inject_script/g,'script')
    },
    contactLink() {
      const linkGenerator: { [k: string]: () => string } = {
        whatsapp: () => `https://wa.me/${this.myContact}`,
        telegram: () => `https://t.me/${this.myContact}`,
        messenger: () => `http://m.me/${this.myContact}`,
        instagram: () => `https://www.instagram.com/${this.myContact}`
      };
      return linkGenerator[this.linkTo]();
    }
  }
})
