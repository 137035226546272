
import Vue from 'vue';
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy)
import BubbleBuilder from './components/BubbleBuilder.vue';

export default Vue.extend({
  name: 'App',
  data() {
    return {
      logo: 'https://superon.lifeapps.com.br/front-vue/img/logo-lifeapps.4a7b02e0.png'
    }
  },
  components: {
    BubbleBuilder
  }
});
